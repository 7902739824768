section#error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  padding: 0;
  background-color: #15152b;
  color: #fff;
  text-align: center;
}

section#error .container {
  animation-name: slide-in;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

section#error img {
  display: block;
  width: 200px;
  margin: 1.625em auto;
  opacity: .1;
}

section#error h1 {
  line-height: 1.1;
  letter-spacing: -2px;
  color: inherit;
  flex-basis: 100%;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
}

section#error h1 small {
  display: block;
  font-size: .5em;
  letter-spacing: 0;
  opacity: .5;
}

/* Standard syntax */
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
